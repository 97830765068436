import _ from 'lodash'
import storage from 'constants/storage'
import type { Challenge } from 'libs/challenge'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import type { User } from 'libs/user'
import * as userLib from 'libs/user'

const HOME = '/'
const HOME_ARTHRITIS = '/arthritis'

const APP = '/app/'
const HOME_AUTHED = APP
const ACTIVITY = `${APP}activity/`
const ADMIN = `${APP}admin/`
const ASSESSMENTS = '/assessments/'
const BLOG = '/blog/'
const DOWNLOAD = '/download/'
const EXPLORE = `${APP}explore/`
const FAQ = '/faq/'
const PRESS = '/press/'
const ONBOARDING_ASSESSMENT_RESULTS = `${APP}onboarding/assessment-results/`
const ONBOARDING_ENTERPRISE_ELIGIBILITY = `${APP}onboarding/enterprise/eligibility/`
const ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK = `${APP}onboarding/eligibility-recheck/`
const ONBOARDING_ENTERPRISE_ELIGIBILITY_CHECK_LOADER = `${APP}onboarding/checking-eligibility/`
const ONBOARDING_INTRO = `${APP}onboarding/intro/`
const ONBOARDING_PAYMENT = `${APP}onboarding/payment/`
const ONBOARDING_PROFILE = `${APP}onboarding/profile/`
const ONBOARDING_PROGRAM_BUILD = `${APP}onboarding/program/build/`
const ONBOARDING_PROGRAM_INTRODUCTION = `${APP}onboarding/program/introduction/`
const ONBOARDING_COMMITMENT = `${APP}onboarding/program/commitment/`
const ONBOARDING_QUESTIONS = `${APP}onboarding/questions/`
const PLAYLIST = `${APP}playlist/`
const PROGRAM = `${APP}program/`
const SETTINGS = `${APP}settings/`

// TODO: grab from libs/enterprise
// enterprise landing
const ENTERPRISE_GATOR = '/genspace/'
const ENTERPRISE_GROUPER = '/uhcgroup/'
const ENTERPRISE_HERRING = '/humangoodlg/'
const ENTERPRISE_ORCA = '/onepass/'
const ENTERPRISE_OTTER = '/optumaco/'
const ENTERPRISE_REEF = '/renewactive/'
const ENTERPRISE_URCHIN = '/uclahealthma/'

// the enterprise checks here are still a little odd but…it works
function getHomePath(user?: User) {
  if (!_.isEmpty(user)) {
    if (userLib.isEnterpriseUser(user)) {
      return enterprise.getHomePath(user.enterpriseMember.enterprise.internalId)
    }

    return HOME_AUTHED
  }

  const homeCookie = cookie.getCookie(storage.HOME_KEY)
  if (homeCookie) {
    if (userLib.hasHome(storage.HOME_ARTHRITIS)) {
      return HOME_ARTHRITIS
    }

    if (userLib.hasHome(storage.HOME_BALANCED)) {
      return `/${storage.HOME_BALANCED}/`
    }

    return enterprise.getHomePath(homeCookie)
  }

  return HOME
}

function getChallengePath(challenge: Challenge) {
  return challenge.collectionId
    ? `${PLAYLIST}${challenge.collectionId}`
    : challenge.newProgramId
      ? PROGRAM
      : EXPLORE
}

function getProgramPath(user: User) {
  return userLib.hasDashboard(user) ? PROGRAM : HOME_AUTHED
}

function isUnauthedPage(path: string) {
  return !path?.includes('/app/')
}

function isEnterpriseLandingPage(path: string) {
  const removeForwardSlashes = (p) => p.replaceAll('/', '')

  return [
    removeForwardSlashes(ENTERPRISE_GATOR),
    removeForwardSlashes(ENTERPRISE_GROUPER),
    removeForwardSlashes(ENTERPRISE_HERRING),
    removeForwardSlashes(ENTERPRISE_ORCA),
    removeForwardSlashes(ENTERPRISE_OTTER),
    removeForwardSlashes(ENTERPRISE_REEF),
    removeForwardSlashes(ENTERPRISE_URCHIN),
  ].includes(removeForwardSlashes(path))
}

export default {
  APP,
  HOME,
  HOME_SPECIAL: '/special',
  ABOUT: '/about/',
  ASSESSMENTS,
  ASSESSMENT_BALANCE: `${ASSESSMENTS}balance/`,
  ASSESSMENT_EXERCISE_RELATIVE: 'exercise/',
  ASSESSMENT_RESULTS_RELATIVE: 'results/',
  BLOG,
  BLOG_5_BOLD_WORKOUTS_TO_PAIR_WITH_YOUR_WALKING_ROUTINE: `${BLOG}5-bold-workouts-to-pair-with-your-walking-routine/`,
  BLOG_SERIES_A: `${BLOG}the-gap-between-healthspan-and-lifespan-—-and-what-we-can-do-about-it/`,
  CAREERS: '/careers/',
  CONTACT: '/contact/',
  DISCLAIMERS: '/disclaimers/',
  DOWNLOAD,
  DOWNLOAD_FACEBOOK: `${DOWNLOAD}facebook/`,
  ENTERPRISE_GATOR,
  ENTERPRISE_GROUPER,
  ENTERPRISE_HERRING,
  ENTERPRISE_ORCA,
  ENTERPRISE_OTTER,
  ENTERPRISE_REEF,
  ENTERPRISE_URCHIN,
  FAQ,
  FAQ_ENTERPRISE_CLINICAL: `${FAQ}clinical/`,
  FAQ_ENTERPRISE_FITNESSS: `${FAQ}fitness/`,
  GIFT_BOLD: '/gift-bold/',
  PARTNERSHIPS: '/partnerships/',
  PASSWORD_FORGOT: '/forgot-password/',
  PASSWORD_RESET: '/reset-password/',
  PRESS,
  PRESS_BOLD_ANNOUNCES_7_MILLION_IN_SEED_FUNDING: `${PRESS}bold-announces-7-million-in-seed-funding/`,
  PRIVACY_POLICY: '/privacy-policy/',
  REQUEST_DEMO: '/request-demo/',
  SCIENCE: '/science/',
  SIGN_IN: '/sign-in/',
  SIGN_UP: '/sign-up/',
  TERMS_OF_SERVICE: '/terms-of-service/',
  TRAINER: '/trainer/',
  TRAINERS: '/trainers/',
  ERROR_NOT_FOUND: '/404/',

  HOME_AUTHED,
  ACTIVITY,
  ACTIVITY_CHALLENGES: `${ACTIVITY}#challenges`,
  ASSESSMENTS_AUTHED: `${APP}assessments/`,
  BADGES: `${APP}badges/`,
  ENTERPRISE_ELIGIBLE: `${APP}enterprise-eligible/`,
  EXPLORE,
  EXPLORE_BOLD_TALKS: `${EXPLORE}bold-talks/`,
  EXPLORE_LESSONS: `${EXPLORE}lessons/`,
  JUST_CHECKING_IN: `${APP}just-checking-in/`,
  LESSON: `${APP}lesson/`,
  LESSONS_FAVORITE: `${APP}lessons/favorite/`,
  LIVE_LESSONS: `${APP}live/`,
  LIVE_LESSON_FEEDBACK: `${APP}live-lesson-feedback/`,
  LIVE_RESTREAM_LESSON: `${APP}live-lesson-restream/`,
  MOVEMENTS: `${APP}movements/`,
  ONBOARD_EXISTING_USER: `${APP}onboard-existing-user/`,
  ONBOARDING_ASSESSMENT_RESULTS,
  ONBOARDING_ENTERPRISE_ELIGIBILITY,
  ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK,
  ONBOARDING_ENTERPRISE_ELIGIBILITY_CHECK_LOADER,
  ONBOARDING_INTRO,
  ONBOARDING_PAYMENT,
  ONBOARDING_PROFILE,
  ONBOARDING_PROGRAM_BUILD,
  ONBOARDING_COMMITMENT,
  ONBOARDING_PROGRAM_INTRODUCTION,
  ONBOARDING_QUESTIONS,
  PELVIC_ASSESSMENT: `${APP}pelvic-assessment/`,
  PLAYLIST,
  PLAYLISTS: `${APP}explore/playlists/`,
  PROFILE: `${APP}profile/`,
  PROGRAM,
  PROGRAM_BUILD: `${APP}program/build/`,
  PROGRAM_CHANGE: `${APP}program/change/`,
  REFERRALS: `${APP}referrals/`,
  SETTINGS,
  SETTINGS_INVOICES: `${SETTINGS}invoices/`,
  SETTINGS_SUBSCRIPTION: `${SETTINGS}subscription/`,
  SETTINGS_SUBSCRIPTION_CHANGE: `${SETTINGS}subscription/update/`,
  SETTINGS_SUBSCRIPTION_CHANGE_SUCCESS: `${SETTINGS}subscription/update/success/`,

  UNSUBSCRIBE: `${APP}unsubscribe/`,

  ONBOARDING_PATHS: [
    ONBOARDING_ASSESSMENT_RESULTS,
    ONBOARDING_ENTERPRISE_ELIGIBILITY,
    ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK,
    ONBOARDING_ENTERPRISE_ELIGIBILITY_CHECK_LOADER,
    ONBOARDING_INTRO,
    ONBOARDING_PAYMENT,
    ONBOARDING_PROFILE,
    ONBOARDING_QUESTIONS,
  ],

  ADMIN: HOME_AUTHED,
  ADMIN_ENTERPRISE_MEMBER_SEARCH: `${ADMIN}enterprise-member-search/`,
  ADMIN_ENTERPRISE_MEMBER_PROFILE: `${ADMIN}enterprise-member-profile/`,
  ADMIN_COLLECTION_CREATE: `${ADMIN}create-collection/`,
  ADMIN_ENTERPRISE: `${ADMIN}enterprise/`,
  ADMIN_LESSON_REGIMENS: `${ADMIN}lessons-regimens/`,
  ADMIN_LESSON_STREAMS: `${ADMIN}lessons-streams/`,
  ADMIN_LESSONS: `${ADMIN}lessons/`,
  ADMIN_USERS: `${ADMIN}users/`,
  ADMIN_ANALYTICS: `${ADMIN}analytics/`,

  getChallengePath,
  getHomePath,
  getProgramPath,
  isEnterpriseLandingPage,
  isUnauthedPage,
}
