export default {
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  NO_OP: 204,
  NOT_FOUND: 404,
  POST_SUCCESS: 201,
  SUCCESS: 200,
  TOO_MANY_REQUESTS: 429,
}
