import * as environment from 'libs/environment'

const ASSETS = 'https://assets.agebold.com'

function getAssetUrl(asset: string): string {
  return `${ASSETS}/${asset}`
}

export default {
  HOME: environment.DOMAIN,
  ASSETS,
  PRODUCTION: environment.DOMAIN_PRODUCTION,

  APPLE_APP_STORE: 'https://apps.apple.com/app/bold-science-backed-exercises/id6478184749',
  APPLE_APP_STORE_WITH_PPID:
    'https://apps.apple.com/app/bold-science-backed-exercises/id6478184749?ppid=4476429e-f88b-4854-ab2e-0f611d1039e5',

  GOOGLE_PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.agebold.agebold',

  APP_URI: 'agebold-mobile://',

  APP_BRANCH_URL: 'https://link.agebold.com',

  FACEBOOK: 'https://www.facebook.com/agebold',
  INSTAGRAM: 'https://www.instagram.com/agebold',
  LINKEDIN: 'https://www.linkedin.com/company/agebold/',

  PAIRTU: 'https://pairtu.com?utm_source=bold&utm_campaign=bold_referral_test',

  getAssetUrl,
}
