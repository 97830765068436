import { equalsIgnoreCase } from '@bold/common/libs/common'
import _ from 'lodash'
import storage from 'constants/storage'
import * as auth from 'libs/auth'
import * as cookie from 'libs/cookie'
import * as date from 'libs/date'
import * as logging from 'libs/logging'
import platformLib from 'libs/platform'

export const ACTION_REACTIVATE_SUBSCRIPTION = 'REACTIVATE_SUBSCRIPTION'
export const ACTION_REMOVE_USER_FROM_SUBSCRIPTION = 'REMOVE_USER_FROM_SUBSCRIPTION'
const USER_OFFLINE_ERROR_TEXT = 'User is offline'

const API_ENV = process.env.GATSBY_BOLD_API_ENV || ''

const API_URLS = {
  production: 'https://api.agebold.com/api',
  staging: 'https://staging-api.agebold.com/api',
  development: 'https://dev-api.agebold.com/api',
  local: 'http://localhost:3000/api',
}

const API_URL_ROOT = API_URLS[API_ENV] || API_URLS.staging

const PING_URL =
  API_ENV === 'production'
    ? 'https://d3rlngje6k6tf4.cloudfront.net/ping'
    : 'https://dsu07eb5w14jf.cloudfront.net/ping'

const POST_SESSIONLESS_REQUEST_PATHS = [
  'sessions',
  'singleSignOn',
  'signOut',
  'authenticate',
  'registerIpForAppDownload',
]
const PUT_SESSIONLESS_REQUEST_PATHS = ['updatePassword']

let refreshTokenPromise = null
let processCounter = 0 // prevent race conditions, limit counter to 1

function getAccessToken() {
  return cookie.getCookie(storage.ACCESS_TOKEN_KEY)
}

function buildHeaders(headerParameters?: {}) {
  const commonHeaders = {
    'cache-control': 'no-cache',
    'Content-Type': 'application/json',
    'x-platform-type': 'web',
    'x-user-device': platformLib.getUserDevice(),
    'x-user-timezone': date.TIMEZONE,
  }

  const headers = _.isUndefined(getAccessToken())
    ? commonHeaders
    : {
        ...commonHeaders,
        Authorization: `Bearer ${getAccessToken()}`,
      }

  if (!_.isEmpty(headerParameters)) {
    _.forEach(Object.keys(headerParameters), (parameter) => {
      headers.append(parameter, headerParameters[parameter])
    })
  }

  return headers
}

function pathIsSessionless(path, method = '') {
  let isSessionless = false
  let sessionlessPaths = null
  switch (method.trim().toUpperCase()) {
    case 'POST': {
      sessionlessPaths = POST_SESSIONLESS_REQUEST_PATHS
      break
    }
    case 'PUT': {
      sessionlessPaths = PUT_SESSIONLESS_REQUEST_PATHS
      break
    }
    default: {
      sessionlessPaths = []
      break
    }
  }
  sessionlessPaths.forEach((sessionlessPath) => {
    if (path.includes(sessionlessPath)) {
      isSessionless = true
    }
  })
  return isSessionless
}

async function processResponse(path: string, responseJson: unknown, callback: unknown) {
  if (path.includes('refreshTokens')) {
    return responseJson
  }
  if (processCounter !== 0) {
    processCounter = 0
    return responseJson
  }

  // handle expired access token
  // if simultaneous api calls get the token error, only call the refresh method once
  if (responseJson.statusCode === 401 || responseJson.code === 401) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = auth.refreshTokens().then(() => {
        refreshTokenPromise = null
      })
    }
    return refreshTokenPromise.then(async () => {
      // if expired access token, try again after the refresh token is regenerated
      processCounter += 1
      if (responseJson.message === 'Access token expired') {
        return callback().then((r) => r)
      }
      // otherwise you're not allowed to be here - sign out!
      return await auth.signOut({ error: true })
    })
  }

  return responseJson
}

async function isOnline() {
  if (!navigator.onLine) return false
  try {
    const result = await fetch(PING_URL)
    return result.status === 204
  } catch {
    return false
  }
}

// catch fetch errors and check if they're due to client network issues
export async function smartFetch(
  url: string,
  parameters: {
    headers: any
    body?: string
    method?: string
    isRetry?: boolean
    originalError?: Error
  }
) {
  let result
  try {
    //check once here before making api call
    if (!navigator.onLine) throw new Error(USER_OFFLINE_ERROR_TEXT)
    result = await fetch(url, parameters)
  } catch (error) {
    // Throw the error if the request has already been retried.
    // No need to recheck the users online status
    if (parameters.isRetry) {
      throw error
    }

    const message = error.message
    if (
      equalsIgnoreCase(message, 'Failed to fetch') ||
      equalsIgnoreCase(message, 'Load failed') ||
      equalsIgnoreCase(message, 'signal timed out')
    ) {
      // Try ping request to check if user is online
      if (!(await isOnline())) {
        throw new Error(USER_OFFLINE_ERROR_TEXT)
      }

      // Ping succeeded. Retry the original request
      logging.toDatadog(`Retry ${parameters.method || 'GET'} request to ${url}`)

      return await smartFetch(url, {
        ...parameters,
        isRetry: true,
      })
    }
  }
  return result
}

export async function get(path: string, headers?: {}) {
  const result = await smartFetch(`${API_URL_ROOT}/${path}`, {
    headers: buildHeaders(headers),
  })
  const json = await result.json()
  if (pathIsSessionless(path, 'GET')) return json
  return await processResponse(path, json, () => get(path, headers))
}

export async function post(path: string, body: unknown, headers?: {}) {
  const result = await smartFetch(`${API_URL_ROOT}/${path}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: buildHeaders(headers),
  })
  const json = await result.json()
  if (pathIsSessionless(path, 'POST')) return json
  return await processResponse(path, json, () => post(path, body, headers))
}

export async function put(path: string, body: unknown, headers?: {}) {
  const result = await smartFetch(`${API_URL_ROOT}/${path}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: buildHeaders(headers),
  })
  const json = await result.json()
  if (pathIsSessionless(path, 'PUT')) return json
  return await processResponse(path, json, () => put(path, body, headers))
}

export async function postFileData(path: string, data: unknown) {
  const result = await fetch(`${API_URL_ROOT}/${path}`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'cache-control': 'no-cache',
    },
  })
  const json = await result.json()
  return await processResponse(path, json, () => postFileData(path, data))
}

export async function putFileData(path: string, data: unknown) {
  const result = await fetch(`${API_URL_ROOT}/${path}`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': `Bearer ${getAccessToken()}`,
      'cache-control': 'no-cache',
    },
  })
  const json = await result.json()
  if (pathIsSessionless(path, 'PUT')) return json
  return await processResponse(path, json, () => putFileData(path, data))
}

export async function deleteApi(path: string) {
  const result = await fetch(`${API_URL_ROOT}/${path}`, {
    method: 'delete',
    headers: buildHeaders(),
  })
  const json = await result.json()
  if (pathIsSessionless(path, 'DELETE')) return json
  return await processResponse(path, json, () => deleteApi(path))
}

export async function downloadCsv(path: string) {
  return await fetch(`${API_URL_ROOT}/${path}`, {
    headers: buildHeaders(),
  })
}
