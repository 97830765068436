import { utcToZonedTime } from "date-fns-tz";
import { format, startOfWeek, subDays } from "date-fns";
import _ from "lodash";
import * as date from "libs/date";
import { DateTime } from "luxon";

export const equalsIgnoreCase = (string1: string, string2: string) => {
  if (typeof string1 !== "string" || typeof string2 !== "string") {
    return false;
  }
  return string1.trim().toLowerCase() === string2.trim().toLowerCase();
};

export function getUniqueDaysActiveThisWeek(lessons: any) {
  // standardize everything to user's timezone
  const now = new Date();
  const startOfWeekInTargetTZ = utcToZonedTime(
    startOfWeek(now, { weekStartsOn: 1 }),
    date.TIMEZONE,
  );
  const sundayThisWeek = subDays(startOfWeekInTargetTZ, 1); // week starts on Monday in date-fns but we start on Sunday
  const lessonsByZonedDate = lessons?.map((item: any) => {
    const itemDate = new Date(item.createdAt);
    const zonedItemDate = utcToZonedTime(itemDate, date.TIMEZONE);
    return {
      ...item,
      zonedItemDate,
    };
  });
  const lessonsThisWeek = lessonsByZonedDate?.filter(
    (item: any) => item.zonedItemDate >= sundayThisWeek,
  );
  const uniqueDaysActive = _.uniqBy(lessonsThisWeek, (item: any) =>
    format(item.zonedItemDate, "yyyy-MM-dd"),
  );

  return uniqueDaysActive;
}

export function getTimezone() {
  try {
    const timezone = DateTime.local().zoneName;
    if (!_.isNil(timezone)) {
      return timezone;
    }
    return "America/Chicago"; // CT as backup
  } catch {
    return "America/Chicago"; // CT as backup
  }
}
